<template>
  <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
    <span :class="{ 'has-text-weight-semibold has-text-primary': isActive }">{{ group.name }}</span>
    <el-checkbox v-model="isMember" v-loading="isSaving" :disabled="isSaving" />
  </li>
</template>

<script>
import _removeItem from 'lodash/filter'
import { mapActions } from 'vuex'

export default {
  props: {
    group: {
      type: Object,
      required: true
    },

    relation: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isSaving: false
  }),

  computed: {
    isMember: {
      get () {
        return this.relation.groups.includes(this.group.id)
      },
      set (value) {
        this.storePresense(value)
      }
    }
  },

  methods: {
    ...mapActions('connect/groups', [
      'addRelationsToGroup',
      'removeRelationsFromGroup'
    ]),

    async storePresense (isAdding) {
      this.isSaving = true

      let payload = {
        groupId: this.group.id,
        relations: [this.relation.id]
      }

      try {
        if (isAdding) {
          await this.addRelationsToGroup(payload)

          this.relation.groups.push(this.group.id)

          this.$notify.success(this.$t('success.relation_group_member_added'))
        } else {
          await this.removeRelationsFromGroup(payload)

          this.relation.groups = _removeItem(this.relation.groups, r => r !== this.group.id)

          this.$notify.success(this.$t('success.relation_group_member_removed'))
        }
      } catch (error) {
        this.$notify.error(this.$t('errors.error_occurred'))
      }

      this.isSaving = false
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.el-dropdown-menu .Relation-group__selector {
  min-width: 300px;
  padding: $padding;
  background-color: $grey-lighter;

  h4 {
    font-weight: bold;
    margin-bottom: $margin-small;
  }
}
</style>
