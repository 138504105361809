<template>
  <div class="Relation-group__selector">
    <template v-if="groups.length">
      <h4 class="m-b-l">
        {{ $t('components.relation_group_selector.list_title') }}
      </h4>
      <ul>
        <relation-group-selector-switch
          v-for="group in groups"
          :key="group.id"
          :group="group"
          :relation="relation"
          :is-active="groupId === group.id"
        />
      </ul>
    </template>
    <div v-else>
      {{ $t('components.relation_group_selector.create_new_group') }}
      <router-link :to="{ name:'connect.groups' }" class="has-text-decoration-underline">
        {{ $t('components.relation_group_selector.groups_page') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RelationGroupSelectorSwitch from '@/components/connect/groups/RelationGroupSelectorSwitch'

export default {
  inject: { groupId: { default: '' } },

  components: {
    RelationGroupSelectorSwitch
  },

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      groups: 'connect/groups/all'
    })
  }
}
</script>

<style lang="scss">
@import "~utils";

.el-dropdown-menu .Relation-group__selector {
  padding: $padding-large $padding;
  min-width: 300px;
  margin-top: $margin-small;
  margin-bottom: -9px;
  background-color: $grey-lighter;
  border: 1px solid $white;

  h4 {
    font-weight: bold;
    margin-bottom: $margin-small;
  }
}
</style>
