<template>
  <div
    v-loading="isSubmitting"
    class="Contact Card"
    data-testid="RelationCard"
  >
    <div class="Card__header has-background p-v-m is-size-3 is-flex is-aligned-justify is-aligned-middle">
      <h4>
        <router-link
          :to="{ name: 'connect.relation.view', params: { relationId: relation.id } }"
          data-testid="relationProfileLink"
        >
          {{ relation.preferred_name }}
        </router-link>
      </h4>

      <el-dropdown
        v-show="authorizeBool('relation.view')"
        trigger="click"
      >
        <div
          class="el-dropdown-link"
          data-testid="relationMenu"
        >
          <v-button class="is-plain is-round is-dark has-background">
            <span class="icon">
              <v-icon type="ellipsis-v" size="lg" />
            </span>
          </v-button>
        </div>

        <el-dropdown-menu slot="dropdown" :data-testid="`RelationCard-${relation.id}__DropDown`">
          <el-dropdown-item
            class="p-l-none m-r-none has-text-black"
            @click.native="$router.push({ name: 'connect.relation.view', params: { relationId: relation.id } })"
          >
            <v-icon
              type="eye"
              class="m-l-m"
            />
            {{ $t('general.see_details') }}
          </el-dropdown-item>

          <template
            v-if="fullOptions"
            class="is-block"
          >
            <el-dropdown-item
              class="p-l-none m-r-none has-text-black"
              data-testid="relationEdit"
              @click.native="$router.push({ name: 'relations.edit', params: { relationId: relation.id } })"
            >
              <v-icon
                type="pencil"
                class="m-l-m"
              />
              {{ $t('forms.edit') }}
            </el-dropdown-item>

            <el-dropdown-item
              class="p-l-none m-r-none has-text-black"
              data-testid="relationComposeEmail"
              @click.native="composeEmail"
            >
              <v-icon
                type="envelope"
                class="m-l-m"
              />
              {{ $t('forms.email') }}
            </el-dropdown-item>

            <el-dropdown-item
              class="p-l-none m-r-none"
              @click.native="unlink"
            >
              <a class="has-text-danger">
                <v-icon
                  type="trash"
                  class="m-l-m"
                />
                {{ $t('general.delete') }}
              </a>
            </el-dropdown-item>
          </template>
          <relation-group-selector :relation="relation" />
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="Card__body has-background-grey-lighter">
      <div class="columns">
        <div class="Contact__details column is-3 is-flex">
          <router-link :to="{ name: 'connect.relation.view', params: { relationId: relation.id } }">
            <round-avatar
              :src="relation.picture_url"
              :acronym-fallback="relation.preferred_name"
              :fallback="$asset('/images/general/generic_avatar_placeholder.jpg')"
              size="large"
            />
          </router-link>
        </div>

        <div class="Contact__extra column is-9">
          <div class="Contact__extra__brand">
            <h5>
              <i class="hf hf-fw hf-person" />
              <span v-if="relation.associations && relation.associations.length && relation.associations[0].role">{{ ucfirst(truncate(relation.associations[0].role, 50)) }}</span>
              <span v-else>{{ $t('general.journalist') }}</span>
            </h5>

            <h5>
              <i class="hf hf-fw hf-house" />
              <span v-if="relation.associations && relation.associations.length && relation.associations[0].association">{{ ucfirst(truncate(relation.associations[0].association, 50)) }}</span>
              <span v-else>{{ $t('general.not_available') }}</span>
            </h5>

            <h5 v-if="relation.locations && relation.locations.length">
              <country-flag :country="relation.locations[0].country_code" class="nationality" />
              <span>{{ getCountryNameByIsoCode2(relation.locations[0].country_code) }}</span>
            </h5>
            <h5 v-else>
              <em>Country not detected</em>
            </h5>
          </div>
        </div>
      </div>

      <div class="columns is-multiline m-b-none">
        <div class="Contact__details is-12-tablet column is-3-desktop">
          <social-profiles :source="relation" :primary-only="true" />
        </div>

        <div class="Contact__social column is-12-tablet is-9-desktop is-flex is-aligned-middle">
          <social-profiles :source="relation" :secondary-only="true" />
        </div>
      </div>

      <div class="columns m-t-xs m-b-xs border-t-grey-light" style="overflow: auto;">
        <div class="column is-6">
          <div class="Contact__categories">
            <p class="p-b-xs">
              <v-icon type="heart" />
              {{ $t('general.categories') }}:
            </p>

            <template v-if="relation.categories && relation.categories.length">
              <span
                v-for="(category, index) in categories"
                :key="index"
                class="Tag"
              >
                {{ category }}
              </span>
            </template>
            <span
              v-else
              class="fallback"
            >
              {{ $t('general.not_available') }}
            </span>
          </div>
        </div>

        <div class="column is-6">
          <div class="Contact__topics">
            <p class="p-b-xs">
              <v-icon type="tag" />
              {{ $t('general.tags') }}:
            </p>

            <template v-if="relation.tags && relation.tags.length">
              <span
                v-for="(topic, index) in relation.tags"
                :key="index"
                :title="topic"
                class="Tag is-light"
              >
                {{ truncate(topic, 20) }}
              </span>
            </template>

            <span
              v-else
              class="fallback"
            >
              {{ $t('general.not_available') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ucfirstMixin from '@hypefactors/shared/js/mixins/UcfirstMixin'
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

import SocialProfiles from '@/components/connect/_shared/SocialProfiles'
import RelationGroupSelector from '@/components/connect/groups/RelationGroupSelector'

export default {
  components: {
    SocialProfiles,
    RelationGroupSelector
  },

  mixins: [ucfirstMixin, TruncateMixin],

  props: {
    relation: {
      type: Object,
      required: true
    },

    fullOptions: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    isSubmitting: false
  }),

  computed: {
    ...mapGetters(['getCountryNameByIsoCode2']),

    categories () {
      return this.relation.categories.map((category) => this.$t(`connect-categories.${category}`))
    }
  },

  methods: {
    unlink () {
      this.$confirm(this.$t('components.relation_card.confirm_delete_relation'))
        .then(() => {
          this.isSubmitting = true

          this.$api.delete(`/connect/relations/${this.relation.id}`, {
            data: { brand_id: this.activeBrandId }
          })
            .then(() => {
              this.isSubmitting = false

              this.$emit('relation-unlinked', this.relation)

              this.$notify({
                title: this.$t('general.success'),
                message: this.$t('success.relation_removed'),
                type: 'success'
              })
            })
            .catch(error => {
              this.isSubmitting = false

              this.$notify({
                title: this.$t('general.error'),
                message: this.$t('errors.cannot_remove_relation', { message: error.message }),
                type: 'error'
              })

              console.error(error)
            })
        })
        .catch(_ => {})
    },

    /**
     * Go to compose email with this relation prefilled
     */
    composeEmail () {
      const recipient = JSON.stringify({ name: this.relation.preferred_name, relation_id: this.relation.id })

      this.$router.push({ name: 'emails.new', query: { recipient } })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Contact {
  @include transit(color);

  .Card__body {
    padding-bottom: 0;
  }

  .nationality {
    width: 12px;
    height: 12px;
    object-fit: cover;
    margin-right: $margin-small;
    border-radius: 100%;
  }

  .Contact__extra {
    &__brand {
      height: 100%;
    }

    h5 {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: $margin-small;
      font-size: $hf__font-label;
      font-weight: bold;
      text-align: left;

      i {
        font-size: $hf__font-label;
        margin-right: $margin-tiny;
        color: $hf__text-muted;
        @include rtl {
          margin-left: $margin-tiny;
          margin-right: 0;
        }
      }
    }
  }

  &__topics,
  &__categories {
    height: 5.4rem;
    margin: 0;

    p {
      margin: $margin-tiny;
      margin-bottom: 0;
      font-size: $hf__font-label;
      font-weight: bold;
      text-transform: uppercase;
    }

    .fallback {
      padding: 0.1rem $padding-small;
      margin: $margin-tiny;
      font-size: $hf__font-label;
      color: $hf__text-muted;
    }
  }

  &__topics {
    border-left: $hf__border--base;
  }

  .Card__header {
    h4 {
      font-weight: bold;
    }

    p {
      font-size: $hf__font-label;
    }
  }

  .Card__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: $hf__font-label-small;
      font-weight: bold;
      color: $hf__text-muted;
      text-transform: uppercase;
    }
  }
}
</style>
